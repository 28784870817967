import { Pipe, PipeTransform } from "@angular/core";
import { IAddress } from "../../departments/models/department";

@Pipe({ name: 'address' })
export class AddressPipe implements PipeTransform {
  transform(address: IAddress, format: 'short' | 'long' = 'short') {
    if (format !== 'long') {
      return [
        address.city,
        address.street,
        address.streetNumber
      ].filter(a => a).join(', ');
    } else {
      return [
        address.country,
        address.city,
        address.street,
        address.streetNumber,
        address.zip
      ].filter(a => a).join(', ');
    }
  }
}
