<button mat-fab (click)="$event.preventDefault(); imageInput.click();">
  <mat-icon>cloud_upload</mat-icon>
  <!-- <mat-icon *ngIf="!selectedFile?.pending">cloud_upload</mat-icon> -->
  <!-- <mat-spinner *ngIf="selectedFile?.pending" color="primary" [value]="uploadPercent | async"  diameter="25"></mat-spinner> -->
</button>

 <input #imageInput
         type="file"
         accept="image/*"
         (change)="onFileChange(imageInput)">
