import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MatMomentDateModule,
} from '@angular/material-moment-adapter';
import { PhotoGalleryModule } from './components/photo-gallery/photo-gallery.module';
import { FileUploaderModule } from './components/file-uploader/file-uploader.module';
import { AddressPipe } from './pipes/address.pipe';
import {MatChipsModule} from '@angular/material/chips';

@NgModule({ declarations: [
        AddressPipe
    ],
    exports: [
        ReactiveFormsModule,
        MatIconModule,
        MatButtonModule,
        MatChipsModule,
        MatToolbarModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatMenuModule,
        MatDatepickerModule,
        PhotoGalleryModule,
        FileUploaderModule,
        MatSnackBarModule,
        AddressPipe
    ], imports: [CommonModule,
        ReactiveFormsModule,
        MatIconModule,
        MatChipsModule,
        MatButtonModule,
        MatToolbarModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatMenuModule,
        MatDatepickerModule,
        MatDatepickerModule,
        MatMomentDateModule,
        PhotoGalleryModule,
        FileUploaderModule,
        MatSnackBarModule], providers: [
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { strict: true } },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class CoreModule {}
