<mat-card class="photo-gallery-list">
  <mat-card-header>
    <mat-card-title>Gallery</mat-card-title>
    <mat-card-subtitle>{{ photos?.length }} фото</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div class="photo-gallery-list__item"
      [ngClass]="{ 'selected': photo.isSelected }"
      *ngFor="let photo of photos;"
      (click)="selectPhoto(photo)">
      <button class="hover-remove-btn" mat-icon-button (click)="deletePhoto(photo)">
        <mat-icon>remove_circle</mat-icon>
      </button>
      <img class="photo-gallery-list__image" [src]="photo.url">
    </div>
    <app-file-uploader (uploaded)="onUploaded($event)"></app-file-uploader>
  </mat-card-content>
</mat-card>
