import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { ImageSnippet } from './models/image-snippet';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileUploaderComponent implements OnInit {

  @Output() public uploaded: EventEmitter<ImageSnippet> = new EventEmitter();

  public selectedFile: ImageSnippet;
  public uploadPercent: Observable<number>;
  public downloadURL: Observable<string>;

  constructor() { }

  public ngOnInit(): void {
  }

  public onFileChange(imageInput: any){
    const reader = new FileReader();
    const file: File = imageInput.files[0];
  
    reader.readAsDataURL(file);
     
      reader.onload = () => {
        const imageSrc = reader.result as string;
        
        this.selectedFile = new ImageSnippet(imageSrc, file);
        this.selectedFile.pending = true;
        this.uploaded.emit(this.selectedFile);
      };
  }
}
