import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { IPhoto } from '../../models/photo';
import { ImageSnippet } from '../file-uploader/models/image-snippet';

@Component({
  selector: 'app-photo-gallery',
  templateUrl: './photo-gallery.component.html',
  styleUrls: ['./photo-gallery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhotoGalleryComponent implements OnInit {

  @Input() public photos: IPhoto[] = [];

  @Output() public selectedPhoto: EventEmitter<IPhoto> = new EventEmitter();
  @Output() public deletedPhoto: EventEmitter<IPhoto> = new EventEmitter();
  @Output() public uploadedPhoto: EventEmitter<IPhoto> = new EventEmitter();

  constructor() { }

  public ngOnInit(): void {
  }

  public onUploaded(asset: ImageSnippet) {
    const uPhoto: IPhoto = {
      _id: Date.now().toString(),
      name: asset.file.name,
      url: asset.src,
      isSelected: true,
      file: asset.file,
      __new: asset.pending
    };

    this.photos ? this.photos.push(uPhoto) : this.photos = [uPhoto];
    this.selectPhoto(uPhoto);
    this.uploadedPhoto.emit(uPhoto);
  }

  public selectPhoto(photo: IPhoto): void {
    this.unselectAll();
    photo.isSelected = true;
    this.selectedPhoto.emit(photo);
  }

  public unselectAll(): void {
    this.photos.forEach(photo => photo.isSelected = false);
  }

  public deletePhoto(photo: IPhoto): void {
    console.log('Delete photo', photo);
    this.deletedPhoto.emit(photo);
  }
}
